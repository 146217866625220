import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import Add from "./Add";
import Home from "./Home";
import Login from './Login';
import Search from './Search';
import Foreigner from "./Foreigner";
import Reserved from "./Reserved";
import Loading from "./Loading";

import './App.scss';

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            loading: false,
            credentials: this.props.cookies.get('credentials') || {}
        }
    }
    setCredentials(username, password) {
        let credentials = {
            username,
            password
        };
        this.setState({
            loading: true,
            credentials: credentials
        }, function() {
            this.fechData();
        });
    }
    componentDidMount() {
        if(this.state.credentials.username && this.state.credentials.password)
            this.setState({
                loading: true
            }, function() {
                this.fechData();
            });
    }
    async fechData() {
        const response = await fetch(`${process.env.REACT_APP_API}/user`, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Authorization": "Basic " + new Buffer(`${this.state.credentials.username}:${this.state.credentials.password}`).toString('base64')
            }
        });
        if (response.status !== 200) {
            return this.setState({
                loading: false
            });
        }
        this.setState({
            data: await response.json(),
            logged: true,
            loading: false
        });
        this.props.cookies.set('credentials', this.state.credentials, { path: '/', sameSite: 'strict' });
    }
    render() {
        const { data, logged, loading, credentials } = this.state;
        if (!logged && !loading) return <Login credentials={credentials} onSignIn={this.setCredentials.bind(this)} />
        if (!data) return <Loading />;
        return (
            <Router>
                <Switch>
                    <Route
                        path="/"
                        exact={true}
                        render={props => <Home {...props} credentials={credentials} name={data.name} />} />
                    <Route
                        path="/search"
                        exact={true}
                        render={props => <Search {...props} credentials={credentials} />} />
                    <Route
                        path="/contact"
                        exact={true}
                        render={props => <Add {...props} credentials={credentials} />} />
                    <Route
                        path="/reserved"
                        exact={true}
                        render={props => <Reserved {...props} credentials={credentials} />} />
                    <Route
                        path="/contact/:id"
                        exact={true}
                        render={props => <Foreigner {...props} credentials={credentials} name={data.name} />} />
                </Switch>
            </Router>
        );
    }
};
export default withCookies(App);