export const Sanitize = (input) => {
    return input.replace("á","a")
                .replace("à","a")
                .replace("ã","a")
                .replace("â","a")
                .replace("é","e")
                .replace("ê","e")
                .replace("í","i")
                .replace("ó","o")
                .replace("ô","o")
                .replace("õ","o")
                .replace("ú","u")
                .replace("ü","u")
                .replace("ç","c")
                .replace("Á","A")
                .replace("À","A")
                .replace("Ã","A")
                .replace("Â","A")
                .replace("É","E")
                .replace("Ê","E")
                .replace("Í","I")
                .replace("Ó","O")
                .replace("Ô","O")
                .replace("Õ","O")
                .replace("Ú","U")
                .replace("Ü","U")
                .replace("Ç","C");
};
 