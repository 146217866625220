import * as moment from 'moment';
import React, { Component } from "react";
import { Container, Table, Card, Form, Button, Alert} from "react-bootstrap";

import Menu from "./Menu";
import Loading from "./Loading";

class Foreigner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Object.create(null),
            form: {
                date: moment(new Date()).format('YYYY-MM-DD'),
                publisher: this.props.name
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Basic ' + new Buffer(`${this.props.credentials.username}:${this.props.credentials.password}`).toString('base64')
            },
            disabled: false
        }
    }
    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API}/contact/${this.props.match.params.id}`, {
            method: 'GET',
            headers: this.state.headers
        });
        const payload = await response.json();
        if (response.status === 200) {
            this.setState({
                data: payload
            });
        }
    }
    async handleSubmit(e) {
        e.preventDefault();
        // Disable submit button on form submit
        const self = this;
        self.setState({ disabled: true }, async () => {
            // Submit info
            const response = await fetch(`${process.env.REACT_APP_API}/contact/${self.props.match.params.id}`, {
                method: 'POST',
                headers: self.state.headers,
                body: JSON.stringify(self.state.form)
            });
            if (response.status !== 200) {
                return self.setState({
                    error: true,
                    disabled: false
                });
            }
            window.location.reload();
        });
    }
    handleFieldChange(event) {
        const field = event.target.id;
        const value = event.target.value;
        this.setState({
            form: {...this.state.form, [field]: value}
        });
    }
    getMessage() {
        if (this.state.error) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return (
                <Alert variant="danger">Sorry, an error happened on the form submission</Alert>
            );
        }
    }
    render() {
        if(Object.keys(this.state.data).length === 0) {
            return <Loading />;
        }
        else {
            const { name, status, country, telephone, route, street_number, sublocality, locality, location, complement, visits } = this.state.data;
            const elements = [
                ['Name', name],
                ['Status', status ? status.name : null],
                ['Country', country ? country.name : null],
                ['Telephone', telephone],
                ['Address', route],
                ['Number', street_number],
                ['Neighborhood', sublocality],
                ['City', locality],
                ['Location', location],
                ['Additional data', complement]
            ];
            const key = 0;
            const value = 1;
            return (
                <Container className="p-3">
                    <Menu />
                    {this.getMessage()}
                    <Table striped bordered>
                        <tbody>
                            {elements.filter(function(entry) {
                                return entry[value] ? entry : null;
                            }).map((entry, index) => <tr key={entry[key]}>
                                <td className="align-middle">{entry[key]}</td>
                                {(entry[key] === 'Location')
                                ? <td><Button target="_blank" variant="dark" size="sm" href={`https://maps.google.com/?q=${entry[value]}`}>Open on Google Maps</Button></td>
                                : <td dangerouslySetInnerHTML={{__html: entry[value]}} />}
                            </tr>)}
                        </tbody>
                    </Table>
                    {(Object.keys(visits).length > 0) ? <Card className="mt-3 mb-3">
                        <Card.Header>Visits</Card.Header>
                        <Card.Body>
                            {visits.map((entry, key) => <Card key={key} className="vbox">
                                <Card.Body>
                                    <Card.Title dangerouslySetInnerHTML={{__html: entry.publisher}} />
                                    <Card.Text dangerouslySetInnerHTML={{__html: entry.description.replace(/(?:\r\n|\r|\n)/g, '<br />')}} />
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">{entry.date}</small>
                                </Card.Footer>
                            </Card>)}
                        </Card.Body>
                    </Card> : <Alert variant="dark">There is no record of visits</Alert>}
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <Card>
                            <Card.Header>Include</Card.Header>
                            <Card.Body>
                                <Form.Group controlId="publisher">
                                    <Form.Label>Publishers</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={this.props.name}
                                        onChange={this.handleFieldChange.bind(this)} required />
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={this.handleFieldChange.bind(this)}
                                        defaultValue={this.state.form.date}
                                        autoComplete="off" required />
                                </Form.Group>
                                <Form.Group controlId="description" className="mb-0">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        onChange={this.handleFieldChange.bind(this)}
                                        autoComplete="off" required />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <br />
                        <Button type="submit" disabled={this.state.disabled} variant="dark">
                            Submit
                        </Button>
                    </Form>
                </Container>
            )
        }
    }
}
export default Foreigner;