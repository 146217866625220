import React, { Component } from "react";
import Geosuggest from "react-geosuggest";
import GoogleMapReact from "google-map-react";

import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import { Card, Form, Button, Container, Col, Alert } from "react-bootstrap";


import { Sanitize } from "./Utils.js"
import Menu from "./Menu";
import Loading from "./Loading";

const AnyReactComponent = function() {
    return (
        <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png" alt="" />
    );
}

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {},
            disabled: false
        }
        this.center = {
            lat: -19.922706,
            lng: -43.945126
        };
        this.headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": "Basic " + new Buffer(`${this.props.credentials.username}:${this.props.credentials.password}`).toString("base64")
        };
        this.zoom = 11;
    }
    componentDidMount() {
        const self = this;
        ["statuses", "countries", "categories"].forEach(async function(v) {
            const response = await fetch(`${process.env.REACT_APP_API}/${v}`, {
                method: "GET",
                headers: self.headers
            });
            const payload = await response.json();
            if (response.status === 200) {
                self.setState({
                    [v]: payload
                });
            }
        });
    }
    componentDidUpdate() {
        console.log(this.state);
    }
    onSuggestSelect(suggest) {
        const components = suggest.gmaps.address_components;
        const address = {
            locality: this.findAddressComponent(components, "administrative_area_level_2"),
            route: this.findAddressComponent(components, "route"),
            street_number: this.findAddressComponent(components, "street_number"),
            sublocality: this.findAddressComponent(components, "sublocality_level_1"),
            location: `${suggest.location.lat},${suggest.location.lng}`
        };
        this.setState({
            pin: suggest.location,
            zoom: 15,
            form: {...this.state.form, ...address},
            center: suggest.location
        });
    }
    findAddressComponent(components, type) {
        const address = components.find(function(component) {
            return (component.types.indexOf(type) !== -1);
        });
        return (address) ? address["short_name"] : "";
    }
    async handleSubmit(e) {
        e.preventDefault();
        // Disable submit button on form submit
        const self = this;
        self.setState({ disabled: true }, async () => {
            // Submit info
            const response = await fetch(`${process.env.REACT_APP_API}/contact/`, {
                method: "POST",
                headers: self.headers,
                body: JSON.stringify(self.state.form)
            });
            if (response.status !== 200) {
                return self.setState({
                    error: true,
                    disabled: false
                });
            }
            self.setState({
                feedback: await response.json()
            });
        });
    }
    handleFieldChange(event) {
        let field = event.target.id;
        let value = event.target.value;
        if(field === "name")
            value = Sanitize(value);
        this.setState({
            form: {...this.state.form, [field]: value}
        });
    }
    handleFieldCategoryChange(event) {
        let options = event.target.options;
        let categories = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected)
                categories.push(options[i].value);
        }
        this.setState({
            form: {...this.state.form, categories: categories}
        });
    }
    getMessage() {
        if (this.state.error) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            return (
                <Alert variant="danger">Sorry, an error happened on the form submission</Alert>
            );
        }
    }
    render() {
        if(!this.state.statuses || !this.state.countries || !this.state.categories) {
            return <Loading />;
        }
        else if(this.state.feedback) {
            return <Redirect to={`/contact/${this.state.feedback.id}`} />
        }
        else {
            const countries = this.state.countries.map(v => (
                <option value={v.id} key={v.id}>{v.name}</option>
            ));
            const statuses = this.state.statuses.map(v => (
                <Form.Check
                    required
                    type="radio" name="status"
                    label={v.name} key={v.id} value={v.id}
                    onChange={this.handleFieldChange.bind(this)} />
            ));
            const categories = this.state.categories.map(v => (
                <option key={v.id} value={v.id}>{v.name}</option>
            ));
            return (
                <Container className="p-3">
                    <Menu />
                    {this.getMessage()}
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <Card>
                            <Card.Header>Personal Information</Card.Header>
                            <Card.Body>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.name || ""} />
                                </Form.Group>
                                <Form.Group controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as="select" onChange={this.handleFieldChange.bind(this)}>
                                        <option value="" key="0">Uninformed</option>
                                        {countries}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="telephone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.telephone || ""} />
                                </Form.Group>
                                <Form.Group controlId="status" className="mb-0">
                                    <Form.Label>Status</Form.Label>
                                    <Col sm={10}>{statuses}</Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <br />
                        <Card>
                            <Card.Header>Address</Card.Header>
                            <Card.Body>
                                <Geosuggest
                                    inputClassName="form-control"
                                    onSuggestSelect={this.onSuggestSelect.bind(this)} />
                                <div className={"form-gmaps"}>
                                    <GoogleMapReact
                                        zoom={this.zoom}
                                        center={this.center}>
                                        {this.state.pin && <AnyReactComponent
                                            lat={this.state.pin.lat}
                                            lng={this.state.pin.lng} />}
                                    </GoogleMapReact>
                                </div>
                                <Form.Group controlId="route">
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.route || ""} />
                                </Form.Group>
                                <Form.Group controlId="street_number">
                                    <Form.Label>Number</Form.Label>
                                    <Form.Control
                                        type="number" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.street_number || ""} />
                                </Form.Group>
                                <Form.Group controlId="sublocality">
                                    <Form.Label>Neighborhood</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.sublocality || ""} />
                                </Form.Group>
                                <Form.Group controlId="locality">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.locality || ""} />
                                </Form.Group>
                                <Form.Group controlId="location" className="mb-0">
                                    <Form.Label>Coordinates</Form.Label>
                                    <Form.Control
                                        type="text" disabled
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.location || ""} />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <br />
                        <Card>
                            <Card.Header>Other</Card.Header>
                            <Card.Body>
                                <Form.Group controlId="complement">
                                    <Form.Label>Additional address data</Form.Label>
                                    <Form.Control
                                        type="text" autoComplete="off"
                                        onChange={this.handleFieldChange.bind(this)}
                                        value={this.state.form.complement || ""} />
                                </Form.Group>
                                <Form.Group controlId="categories" className="mb-0">
                                    <Form.Label>Categories</Form.Label>
                                    <Form.Control
                                        onChange={this.handleFieldCategoryChange.bind(this)}
                                        as="select" multiple>{categories}</Form.Control>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <br />
                        <Button type="submit" disabled={this.state.disabled} variant="dark">
                            Submit
                        </Button>
                    </Form>
                </Container>
            )
        }
    }
};
export default withCookies(Add);
