import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";


class Menu extends Component {
    render() {
        return (
            <Navbar bg="dark" variant="dark" style={{ marginBottom: '1rem', borderRadius: '0.25rem' }}>
                <Navbar.Brand href="/">BHEC</Navbar.Brand>
                <Nav className="justify-content-end" style={{ width: "100%" }}>
                    <Nav.Link href="/search">Search</Nav.Link>
                    <Nav.Link href="/contact">Include</Nav.Link>
                </Nav>
            </Navbar>
        );
    }
};
export default Menu;
