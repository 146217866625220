import React, { Component } from "react";
import { Container, Card, Button, Modal, Table, Alert } from "react-bootstrap";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
        this.headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Authorization": "Basic " + new Buffer(`${this.props.credentials.username}:${this.props.credentials.password}`).toString("base64")
        };
    }
    async getAssignment() {
        const response = await fetch(`${process.env.REACT_APP_API}/assignment`, {
            method: "GET",
            headers: this.headers
        });
        if (response.status === 200) {
            this.setState({
                assignment: await response.json(),
                message: null
            });
        }
        else if (response.status === 204) {
            this.setState({
                message: {
                    type: 'danger',
                    data: 'Sorry, no contacts available'
                }
            });
        }
    }
    async setAssignment() {
        const response = await fetch(`${process.env.REACT_APP_API}/assignment`, {
            method: "POST",
            headers: this.headers
        });
        if (response.status === 200) {
            this.setState({
                message: {
                    type: 'info',
                    data: 'Contact updated'
                },
                assignment: null
            });
        }
        this.setState({
            modal: false
        });
    }
    getAssignmentButton() {
        if (!this.state.assignment) {
            return (
                <Button block variant="dark" size="lg" onClick={() => this.getAssignment()}>Work</Button>
            )
        }
    }
    getAssignmentCard() {
        if (this.state.assignment) {
            const { id, name, telephone } = this.state.assignment.foreigner;
            return (
                <Card style={{ marginTop: '1rem' }}>
                    <Card.Header>Assignment</Card.Header>
                    <Card.Body>
                        <Table striped bordered>
                            <tbody>
                                <tr key={id}>
                                    <td className="align-middle">Name</td>
                                    <td dangerouslySetInnerHTML={{__html: name}} />
                                </tr>
                                <tr key={this.state.assignment.id}>
                                    <td className="align-middle">Telephone</td>
                                    <td dangerouslySetInnerHTML={{__html: telephone}} />
                                </tr>
                            </tbody>
                        </Table>
                        <Button variant="dark" size="lg" href={`/contact/${id}`} style={{ marginRight: '1rem' }}>View</Button>
                        <Button variant="dark" size="lg" onClick={() => this.setModal(true)}>Done</Button>
                    </Card.Body>
                </Card>
            )
        }
    }
    getMessages() {
        // Show app usage message if set, otherwise show system messages
        if (this.state.message) {
            const { message } = this.state;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            if(message instanceof Array) {
                return (message.map((entry, key) => <Alert variant={entry.type} key={key}>{entry.description}</Alert>))
            }
            const { data, type } = message;
            return (
                <Alert variant={type}>{data}</Alert>
            );
        }
    }
    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API}/messages`, {
            method: 'GET',
            headers: this.state.headers
        });
        const payload = await response.json();
        if (response.status === 200) {
            this.setState({
                message: payload
            });
        }
    }
    setModal(show) {
        this.setState({
            modal: show
        });
    }
    render() {
        return (
            <Container className="p-3">
                <Modal show={this.state.modal} onHide={() => this.setModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Close</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setModal(false)}>No</Button>
                        <Button variant="primary" onClick={() => this.setAssignment()}>Yes</Button>
                    </Modal.Footer>
                </Modal>
                {this.getMessages()}
                <Card>
                    <Card.Header>Menu</Card.Header>
                    <Card.Body>
                        <Button block variant="dark" size="lg" href="/search">Search</Button>
                        <Button block variant="dark" size="lg" href="/contact">Include</Button>
                        <Button block variant="dark" size="lg" href="/reserved">Reserved</Button>
                        {this.getAssignmentButton()}
                    </Card.Body>
                </Card>
                {this.getAssignmentCard()}
            </Container>
        )
    }
}
export default Home;
