import React, { Component } from "react";
import { Card, Form, Button, Container, Alert } from "react-bootstrap";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.credentials.username || '',
            password: this.props.credentials.password || ''
        }
    }
    handleSignIn(e) {
        e.preventDefault();
        const { username, password } = this.state;
        const self = this;
        self.setState({
            password: ''
        }, function() {
            self.props.onSignIn(username, password);
        });
    }
    handleChange(event) {
        let field = event.target.id;
        let value = event.target.value;
        this.setState({
            [field]: value
        });
    }
    getMessage() {
        if (Object.keys(this.props.credentials).length > 0)
            return (
                <Alert variant="danger" className="mt-3">Error on credentials, please log-in.</Alert>
            );
    }
    render() {
        return (
            <Container className="p-3">
                <Form onSubmit={this.handleSignIn.bind(this)}>
                    <Card>
                        <Card.Header>Sign In</Card.Header>
                        <Card.Body>
                                <Form.Group controlId="username">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        required type="email"
                                        value={this.state.username || ''}
                                        onChange={this.handleChange.bind(this)} />
                                </Form.Group>
                                <Form.Group controlId="password" className="mb-0">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password" required
                                        value={this.state.password || ''}
                                        onChange={this.handleChange.bind(this)} />
                                </Form.Group>
                                {this.getMessage()}
                        </Card.Body>
                    </Card>
                    <br />
                    <Button variant="dark" type="submit">
                        Login
                    </Button>
                </Form>
            </Container>
        );
    }
};
export default Login;
