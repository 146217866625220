import React, { Component } from "react";
import { Container, Card, Form, Alert, ListGroup} from "react-bootstrap";

import Menu from "./Menu";
import { Sanitize } from "./Utils.js"

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            telephone: false
        };
        this.timer = null;
    }
    handleFieldChange(event) {
        clearTimeout(this.timer);
        let address = Sanitize(event.target.value);
        if(address.length < 3)
            return;
        this.timer = setTimeout((value) => {
            this.triggerChange(value);
        }, 1000, address);
    }
    triggerChange(value) {
        const self = this;
        self.setState({ loading: true, search: value }, async () => {
            const response = await fetch(`${process.env.REACT_APP_API}/search?address=${value}`, {
                method: 'GET',
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    "X-Requested-With": "XMLHttpRequest",
                    "Authorization": 'Basic ' + new Buffer(`${this.props.credentials.username}:${this.props.credentials.password}`).toString('base64')
                }
            });
            self.setState({ loading: false }, async () => {
                this.setState(response.status !== 200 ? { error: true } : { feedback: await response.json() });
            });
        });
    }
    getMessage() {
        if (this.state.error) {
            return (
                <Alert variant="danger">Sorry, an error happened on the form submission</Alert>
            );
        }
    }
    getResults() {
        if (this.state.loading) {
            return (<Alert variant="dark">Loading...</Alert>);
        }
        else if (this.state.search && this.state.feedback) {
            if(this.state.feedback.length > 0) {
                return (<Card>
                    <ListGroup>
                        {this.state.feedback.map((entry, key) => <ListGroup.Item
                            dangerouslySetInnerHTML={{__html: this.getCard(entry)}}
                            href={`/contact/${entry.id}`} key={key}
                            variant="light" action />)}
                    </ListGroup>
                </Card>);
            }
            else {
                return (
                    <Alert variant="danger">No results</Alert>
                );
            }
        }
    }
    getCard(entry) {
        const _telephone = this.state.telephone && this.checkField(entry.telephone) ?
            `<span class="badge badge-secondary">${entry.telephone}</span>` : '';
        const address = ['route', 'street_number', 'sublocality', 'locality', 'complement'].filter((i) => {
            return this.checkField(entry[i]);
        }).map((x, index, array) => {
            return index === array.length - 1 ? entry[x] : `${entry[x]}, `;
        }).join(``);
        return `<span style="font-size: 14px">${address}</span><br />
                <span class="badge badge-dark">${entry.status.name}</span>
                ${_telephone}`;
    }
    checkField(value) {
        return value !== ''
            && value !== '0'
            && value !== 'Uninformed'
            && value !== 'N&atilde;o informado'
            && value !== 'null'
            && value !== null;
    }
    render() {
        return (
            <Container className="p-3">
                <Menu />
                {this.getMessage()}
                <Card style={{ marginBottom: '1rem' }}>
                    <Card.Header>Search</Card.Header>
                    <Card.Body>
                        <Form.Control
                            type="text" autoComplete="off" required
                            placeholder="Type at least 3 characters"
                            onChange={this.handleFieldChange.bind(this)} />
                        <hr />
                        <Form.Check
                            type="checkbox"
                            label="Show Telephone"
                            checked={this.state.telephone}
                            onChange={() => {
                                this.setState({ telephone: !this.state.telephone });
                            }} />
                    </Card.Body>
                </Card>
                {this.getResults()}
            </Container>
        )
    }
}
export default Search;