import * as moment from 'moment';
import React, { Component } from "react";
import { Container, Card, Alert, Button} from "react-bootstrap";

import Menu from "./Menu";
import Loading from "./Loading";

class Reserved extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Basic ' + new Buffer(`${this.props.credentials.username}:${this.props.credentials.password}`).toString('base64')
            }
        }
    }
    async componentDidMount() {
        const response = await fetch(`${process.env.REACT_APP_API}/reserved`, {
            method: 'GET',
            headers: this.state.headers
        });
        const payload = await response.json();
        if (response.status === 200) {
            this.setState({
                data: payload
            });
        }
    }
	async setContact(id) {
		const response = await fetch(`${process.env.REACT_APP_API}/reserved/${id}`, {
			method: "GET",
			headers: this.headers
		});
		if (response.status > 0) {
			window.location.reload();
		}
	}
    render() {
		if(!this.state.data) {
			return <Loading />;
		}
        else {
            return (
                <Container className="p-3">
                    <Menu />
                    {(Object.keys(this.state.data).length > 0) ? this.state.data.map((entry, key) =>
						<Card key={entry.id} className="mt-3 mb-3">
							<Card.Header dangerouslySetInnerHTML={{__html: entry.foreigner.name}} />
							<Card.Body>
								<Card.Text>
									Last informed contact: <strong style={{ color: moment().diff(moment(entry.check), 'days') > 15 ? 'red' : 'black'}}>{moment(entry.check).fromNow()}</strong>
								</Card.Text>
								<Button variant="secondary" size="sm" href={`/contact/${entry.foreigner.id}`}>View</Button>{' '}
								<Button variant="dark" size="sm" onClick={() => this.setContact(entry.foreigner.id)}>I've contected this foregner</Button>
							</Card.Body>
						</Card> ) : <Alert variant="dark">There is no record of reserved contacts</Alert>}
                </Container>
            )
        }
    }
}
export default Reserved;