import React, { Component } from "react";
import { Spinner} from "react-bootstrap";

import './Loading.scss';

class Loading extends Component {
    render() {
        return <Spinner animation="border" />;
    }
}
export default Loading;